import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "your-first-jamstack-app"
    }}>{`Your first JAMStack app`}</h1>
    <p>{`While you `}<em parentName="p">{`can`}</em>{` build your own JAMStack, that's not the focus of this workshop. We're going to lean on teams of smart people doing the fiddly work for us.`}</p>
    <p>{`That's how you can focus on `}<em parentName="p">{`your`}</em>{` code, not the machinery everyone needs.`}</p>
    <p>{`In the next two exercises, you're going to try Gatsby and NextJS. The most popular frameworks in this space. Others exist, but I'd classify them as experimental.`}</p>
    <p>{`Don't overthink these exercises, they're a hello world 😊`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media3.giphy.com/media/lcs5BL0NIM4WMv61a9/giphy-loop.mp4?cid=4ac046a2x26a883m87tdme9k17gfc2gicqyjap0rv017tr3d&rid=giphy-loop.mp4",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <h2 {...{
      "id": "gatsby"
    }}>{`Gatsby`}</h2>
    <p><a parentName="p" {...{
        "href": "https://gatsbyjs.com/"
      }}>{`Gatsby`}</a>{` is the first framework to popularize the approach of building static-first websites and webapps with React.`}</p>
    <p>{`It's a fantastic framework for building websites with dynamic features. A little clunky for webapps.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Create a new Gatsby project using the default starter.`}</p>
    <pre><code parentName="pre" {...{}}>{`$ gatsby new my-gatsby-project https://github.com/gatsbyjs/gatsby-starter-default
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/starters/?v=2"
      }}>{`Gatsby Starters`}</a>{` are like pre-configured templates you can use to bootstrap development.`}</p>
    <p>{`Run your site locally with `}<inlineCode parentName="p">{`npm start`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{`.`}</p>
    <p>{`Deploy with the `}<inlineCode parentName="p">{`vercel`}</inlineCode>{` command. That's easiest.`}</p>
    <p>{`To deploy with Netlify start by building your site with `}<inlineCode parentName="p">{`yarn build`}</inlineCode>{` or `}<inlineCode parentName="p">{`npm run build`}</inlineCode>{`. Then initialize a Netlify site with `}<inlineCode parentName="p">{`netlify init`}</inlineCode>{`, and use `}<inlineCode parentName="p">{`netlify deploy`}</inlineCode>{` to publish. Set the deploy directory to `}<inlineCode parentName="p">{`public/`}</inlineCode>{`.`}</p>
    <p>{`Try replacing `}<inlineCode parentName="p">{`gatsby-astronaut.png`}</inlineCode>{` in `}<inlineCode parentName="p">{`src/components/image.js`}</inlineCode>{` with a very large photo. You'll see a cool feature that made Gatsby popular.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <h2 {...{
      "id": "nextjs"
    }}>{`NextJS`}</h2>
    <p><a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`NextJS`}</a>{` is Gatsby's younger cousin. These days it can do almost everything Gatsby can, but follows a different design philosophy.`}</p>
    <p>{`Where everything in Gatsby is a plugin, making it harder to configure, NextJS aims to bring everything useful into its core.`}</p>
    <p>{`Plus it supports on-demand page generation, which Gatsby does not.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Create a new NextJS project using the setup script.`}</p>
    <pre><code parentName="pre" {...{}}>{`$ npx create-next-app
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{}}>{`$ yarn create next-app
`}</code></pre>
    <p>{`Run your site locally with `}<inlineCode parentName="p">{`npm run dev`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn dev`}</inlineCode>{`.`}</p>
    <p>{`Deploy with the `}<inlineCode parentName="p">{`vercel`}</inlineCode>{` command. That's easiest.`}</p>
    <p>{`Hosting NextJS on Netlify is a little trickier because of the dynamic nature of static paths. You'll need the `}<a parentName="p" {...{
        "href": "https://github.com/netlify/next-on-netlify"
      }}>{`next-on-netlify`}</a>{` compatibility layer and follow their instructions.`}</p>
    <p>{`We'll use Vercel for NextJS in this workshop because they're optimized to work together.`}</p>
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <h2 {...{
      "id": "github-integration"
    }}>{`GitHub integration`}</h2>
    <p>{`One of the best parts of JAMStack is integrating your hosting with your GitHub. Gives you live previews on every pull request, automatic deploys when you merge to `}<inlineCode parentName="p">{`master`}</inlineCode>{`, and branch previews.`}</p>
    <h3 {...{
      "id": "exercise-2"
    }}>{`Exercise`}</h3>
    <p>{`Create a new GitHub repository for your hello world project and push to master.`}</p>
    <p>{`Go into Netlify and Vercel, find your deployed project, navigate to Settings, and link your project to git.`}</p>
    <p>{`Once connected, make a change on a new branch, and create a pull request. See what happens.`}</p>
    <h3 {...{
      "id": "solution-2"
    }}>{`Solution`}</h3>
    <p>{`If you don't want to pollute your GitHub, watch me do it :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      